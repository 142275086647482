.contentfield {
  height: 100%;
  width: 100%;
  position: relative;
  text-align: center;
  font-size: larger;
  background-color: var(--text-color);
}

.profilepicture {
  object-fit: none;
  object-position: 55% 20%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.title {
  font-family: "Helvetica", sans-serif;
  font-size: 75px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
}
.title:after {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 60px; /* Change this to whatever width you want. */
  padding-top: 20px; /* This creates some space between the element and the border. */
  border-bottom: 4px solid var(--foreground-color2); /* This creates the border. Replace black with whatever color you want. */
}

.backgroundcard {
  margin: auto;
  text-align: left;
  height: 52vh;
  margin-bottom: 30px;
  width: 48vw;
  min-width: 350px;
  border-radius: 2em;
  background: linear-gradient(145deg, #858383, #706e6e);
  box-shadow: 20px 20px 60px #535252, -20px -20px 60px #a5a2a2;
}

.text {
  font-family: Arial, Helvetica, sans-serif;
  color: var(--text-color);
}

.contact {
  background-color: var(--background-color);
}

.formfield {
  color: white;
  border-radius: 5px;
  width: 300px;
  margin: 10px;
  background: #333333;
  box-shadow: inset 8px 8px 17px #292929, inset -8px -8px 17px #3d3d3d;
}

.formsubmit {
  background: linear-gradient(145deg, #4e4e4e, #424242);
  box-shadow: 8px 8px 17px #3a3a3a, -8px -8px 17px #585858;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  width: 64px;
  height: 32px;
}

.bg-info {
  background-color: var(--foreground-color2);
}
#bio {
  scroll-margin: 170px;
}
#projects {
  scroll-margin: 100px;
}
#contact {
  scroll-margin: 70px;
}

.bio-icon {
  max-width: 150px;
  width: 9vw;
  height: 9vw;
}
.bio-div {
  margin: 6vh;
  margin-left: 8vw;
  margin-right: 8vw;
}
.banner {
  width: auto;
  height: calc(100vh - 100px);
}
@media only screen and (max-width:1920px){
  .banner{
    width:100%;
  }
} 
@media only screen and (max-width:720px){
  .banner{
    width:100%;
    height:auto;
  }
} 
.banner-container {
  display: flex;
  justify-content: center;
}

.arrow-head {
  position: fixed;
  top: calc(100% - 6vw);
  left: calc(50% - 2.5vw);
  box-sizing: border-box;
  border-radius: 0.25rem;
  height: 5vw;
  width: 5vw;
  border-style: solid;
  border-color: white;
  border-width: 0px 4px 4px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
  -webkit-transform: rotate(45deg);
  -webkit-animation: fadein 8s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 8s; /* Firefox < 16 */
  -ms-animation: fadein 8s; /* Internet Explorer */
  -o-animation: fadein 8s; /* Opera < 12.1 */
  animation: fadein 8s;
}
.arrow-head:hover {
  border-bottom-width: 8px;
  border-right-width: 8px;
}
.skills-div {
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 8%);
  padding: 4%;
}
.skills-div > * {
  flex: 1 1 auto;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.profile-div {
  width: 50%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  background: #ffffff;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
}
