.footer {
  display: flex;
  justify-content: flex-start;
  width: 100vw;
  height: 100px;
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #494949;
  box-shadow: inset 20px 20px 60px #313131, inset -20px -20px 60px #616161;
}

.footer > a {
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
}

.body.sticky .footer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100vw;
}

.icon {
  object-fit: contain;
  position: relative;
  width: min(1.5vmax, 50px);
  height: min(1.5vmax, 50px);
  border-radius: 50%;
}

.footer-button {
  color: var(--font-color);
  outline: 0;
  border: none;
  font-size: min(1.5vw, 38px);
  margin: 0.4vw;
  margin-top: 53px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  background: transparent;
}

.footer-button:hover() {
  color: var(--foreground-color2);
}

body {
  overflow-x: hidden;
}
