.grid {
  width: 70%;
  margin-left: 14%;
  margin-right: 14%;
}

.projectpage {
  background-color: #f5f5f5;
  padding-bottom: 150px;
}

.shadow {
  box-shadow: 20px 20px 36px #c9c9c9, -20px -20px 36px #ffffff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.article-text {
  font-size: 21px;
  line-height: 32px;
  font-weight: 400;
}
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
