:root {
  --header-color: rgba(0, 0, 0, 1);
  --text-color: rgba(255, 255, 255, 1);
  --background-color: rgba(73, 73, 73, 1);
  --foreground-color1: #7c7a7a;
  --foreground-color2: rgba(255, 93, 115, 1);
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  from {opacity: 0; transform: scale(.7,.7)}
  to {opacity: 1;}
}
.fade-in-element {
  animation-timing-function: ease-in;
  animation: fade-in 1.4s;
}
