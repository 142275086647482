.header-button {
  color: var(--font-color);
  background: var(--background-color);
  margin: 0 9%;
  outline: 0;
  border: none;
  font-size: 1.5rem;
}

.header-button:hover {
  color: var(--foreground-color1);
}
