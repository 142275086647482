.header {
  display: flex;
  margin: 0 0 32px 0;
  width: 100%;
  height: 300px;
  background: var(--background-color);
  color: #fff;
  justify-content: center;
  place-content: center;
  border-bottom-left-radius: 10% 20%;
  border-bottom-right-radius: 10% 50%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
